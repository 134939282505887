* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html,
body,
#root,
.App {
    height: 100%;
}

*,
button,
input {
    border: 0;
    background: none;
}

.Toastify__toast--success {
  background: #709422 !important;
  border-left: 3px solid #57790b;
  border-radius: 5px;
}

.Toastify__toast--error {
  border-left: 3px solid #a92c1f;
  border-radius: 5px;
}

.Toastify__toast-body {
  font-weight: 600;
  font-size: 13px;
  margin-right: 10px;
}
